// --------------------
// INITIAL Bulma Vars, override if needed
// https://bulma.io/documentation/customize/variables/
// --------------------

$black: hsl(0, 0%, 4%);
$black-bis: hsl(0, 0%, 7%);
$black-ter: hsl(0, 0%, 14%);
$grey-darker: hsl(0, 0%, 21%);
$grey-dark: hsl(0, 0%, 29%);
$grey: hsl(0, 0%, 48%);
$grey-light: hsl(0, 0%, 71%);
$grey-lighter: hsl(0, 0%, 86%);
$white-ter: hsl(0, 0%, 96%);
$white-bis: hsl(0, 0%, 98%);
$white: hsl(0, 0%, 100%);
$orange: hsl(14, 100%, 53%);
$yellow: hsl(48, 100%, 67%);
$green: hsl(141, 71%, 48%);
$turquoise: hsl(171, 100%, 41%);
$cyan: hsl(204, 86%, 53%);
$blue: hsl(217, 71%, 53%);
$purple: hsl(271, 100%, 71%);
$red: hsl(348, 100%, 61%);

$family-sans-serif: "Arial", sans-serif;
$family-monospace: monospace;
$render-mode: optimizeLegibility;

$size-1: 3rem; // H1 size
$size-2: 2.5rem; // H2 size
$size-3: 2rem; // H3 size
$size-4: 1.5rem; // H4 size
$size-5: 1.25rem; // H5 size
$size-6: 1rem; //Body size
$size-7: 0.75rem; // Small size

$weight-light: 300;
$weight-normal: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;

$gap: 64px;
$mobile: 601px;
$tablet: 768px;
$desktop: 960px + (2 * $gap);
$widescreen: 1152px + (2 * $gap);
$wide1366: 1366px;
$widescreen-enabled: TRUE;
$fullhd: 1344px + (2 * $gap);
$fullhd-enabled: TRUE;

$easing: ease-out;

$radius-small: 2px;
$radius: 4px;
$radius-large: 6px;
$radius-rounded: 290486px;

$speed: 86ms;

$variable-columns: TRUE;

// --------------------
// DERIVED
// --------------------

// $primary: $turquoise;
// $info: $cyan;
// $success: $green;
// $warning: $yellow;
// $danger: $red;
// $light: $white-ter;
// $dark: $grey-darker;
// $orange-invert: findColorInvert($orange);
// $yellow-invert: findColorInvert($yellow);
// $green-invert: findColorInvert($green);
// $turquoise-invert: findColorInvert($turquoise);
// $cyan-invert: findColorInvert($cyan);
// $blue-invert: findColorInvert($blue);
// $purple-invert: findColorInvert($purple);
// $red-invert: findColorInvert($red);
// $primary-invert: $turquoise-invert;
// $info-invert: $cyan-invert;
// $success-invert: $green-invert;
// $warning-invert: $yellow-invert;
// $danger-invert: $red-invert;
// $light-invert: $dark;
// $dark-invert: $light;

// $background: $white-ter;

// $border: $grey-lighter;
// $border-hover: $grey-light;

// $text: $grey-dark;
// $text-invert: findColorInvert($text);
// $text-light: $grey;
// $text-strong: $grey-darker;

// $code: $red;
// $code-background: $background;
// $pre: $text;
// $pre-background: $background;

// $link: $blue;
// $link-invert: $blue-invert;
// $link-visited: $purple;
// $link-hover: $grey-darker;
// $link-hover-border: $grey-light;
// $link-focus: $grey-darker;
// $link-focus-border: $blue;
// $link-active: $grey-darker;
// $link-active-border: $grey-dark;

// $family-primary: $family-sans-serif;
// $family-code: $family-monospace;

// $size-small: $size-7;
// $size-normal: $size-6;
// $size-medium: $size-5;
// $size-large: $size-4;

// $custom-colors: null;
// $custom-shades: null;

// $colors: mergeColorMaps(("white": ($white, $black), "black": ($black, $white), "light": ($light, $light-invert), "dark": ($dark, $dark-invert), "primary": ($primary, $primary-invert), "link": ($link, $link-invert), "info": ($info, $info-invert), "success": ($success, $success-invert), "warning": ($warning, $warning-invert), "danger": ($danger, $danger-invert)), $custom-colors);
// $shades: mergeColorMaps(("black-bis": $black-bis, "black-ter": $black-ter, "grey-darker": $grey-darker, "grey-dark": $grey-dark, "grey": $grey, "grey-light": $grey-light, "grey-lighter": $grey-lighter, "white-ter": $white-ter, "white-bis": $white-bis), $custom-shades);
// $sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7;

// --------------------
// GENERIC
// --------------------

// $body-background-color: $white;
// $body-size: 16px;
// $body-rendering: optimizeLegibility;
// $body-family: $family-primary;
// $body-color: $text;
// $body-weight: $weight-normal;
// $body-line-height: 1.5;

// $code-family: $family-code;
// $code-padding: 0.25em 0.5em 0.25em;
// $code-weight: normal;
// $code-size: 0.875em;

// $hr-background-color: $background;
// $hr-height: 2px;
// $hr-margin: 1.5rem 0;

// $strong-color: $text-strong;
// $strong-weight: $weight-bold;