// MAIN MOBILE NAVIGATION SETTINGS
// Change MOBILE navigation here
// Do not change desktop navigation settings here!
// All items here must be wrapped by .header-container-m !
.header-container-m,
.mob-menu-trigger,
.mob-menu-trigger-label {
    // Deactivate on all devices except mobile
    // All other settings in _m-pageheader
    @include from($menuswitch) {
        display: none;
    }
}

.header-container-m {
    width: $slideout-width;
    max-width: 100%;
    height: 100%;
    position: fixed;
    top: 50px;
    right: -$slideout-width;
    bottom: 0;
    z-index: 1;
    padding: $slideout-padding-mobile;
    overflow-y: auto;
    border-top: 3px solid #557FB8;
    //display: flex;
    //align-content: start;
    .header-nav {
        width: 100%;
        // Activation on hover
        &-item-level-0 {
            .header-nav-list-level-1 {
                // Disables hover from desktop menu
                position: relative;
            }
            &:hover {
                .header-nav-list-level-1 {
                    // Disables hover from desktop menu
                    display: none;
                    // All other handling on submenu mobile is done via JS
                }
            }
        }
    }
    .item-has-sub > a {
        position: relative;
    }
    .header-nav-list-toggle,
    .header-nav-list-toggle-0,
    .header-nav-list-toggle-1 {
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        height: 100%;
        // arrow-nav missing!
        //@include sassvg('arrow-nav', $black, center center, 15px 15px);
        @include sassvg('arrow-bcd', $black, center center, 15px 15px);
        &.open {
            transform: rotate(180deg);
        }
    }
}

// Side teaser 
// Position is important for slide-in
// Moves the whole page sideways
// Hamburger
.mob-menu-trigger {
    position: absolute;
    clip: rect(0, 0, 0, 0);
}

// Format and add Animation for Hamburger (x instead of =)
.mob-menu-trigger-label {
    position: absolute;
    width: 30px;
    height: 20px;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index: 9999;
    top: 17px;
    right: 17px;
    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        border-radius: 1px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
        background: #F49128;
        @include until($tablet) {
            width: 80%;
        }
    }
    span:nth-child(1) {
        top: 0px;
        transform-origin: left center;
    }
    span:nth-child(2) {
        top: 7px;
        width: 70%;
        left: 30%;
        transform-origin: left center;
        @include until($tablet) {
            width: 60%;
            left: 20%;
        }
    }
    span:nth-child(3) {
        top: 14px;
        transform-origin: left center;
    }
    @include until(430px) {
        right: 3px;
    }
}

// Move item to the left
.mob-menu-trigger:checked + .mob-menu-trigger-label {
    // right: $slideout-width + $slideout-padding; // Icon should not move with the slider
    right: 6px;
    span:nth-child(1) {
        transform: rotate(45deg);
        top: -3px;
        left: 8px;
        @include until($tablet) {
            top: 1px;
        }
    }
    span:nth-child(2) {
        width: 0%;
        opacity: 0;
    }
    span:nth-child(3) {
        transform: rotate(-45deg);
        top: 18px;
        left: 8px;
    }
}

// Move page to the left
.mob-menu-trigger:checked ~ .page-wrap {
    right: $slideout-width;
}

// Move page to the left
.mob-menu-trigger:checked ~ .header-container-m {
    right: 0;
}

// Add transition
.mob-menu-trigger + label,
.page-wrap,
.header-container-m {
    transition: right 0.2s;
}

ul.mobile-header-nav-list-level-0 {
    margin: 0;
    padding: 0;
    li.mobile-header-nav-item-level-0 {
        position: relative;
        padding: 0;
        margin: 0;
        label {
            color: #3068B4;
            font-size: 18px;
            font-weight: bold;
            border-bottom: 1px solid #EAF1F6;
            width: 100%;
            display: block;
            line-height: 40px;
            padding: 0 20px;
            position: relative;
            -o-transition: all 0.2s ease-out 0s;
            -ms-transition: all 0.2s ease-out 0s;
            -moz-transition: all 0.2s ease-out 0s;
            -webkit-transition: all 0.2s ease-out 0s;
            transition: all 0.2s ease-out 0s;
            @include until($ctablet+1) {
                font-size: 14px;
            }
            a {
                color: #3068B4;
                display: block;
                width: 50%;
                &.menu-page.active {
                    color: $color-orange;
                }
            }

        }
        &.item-has-sub {
            label {
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    background: sassvg('ic-plus-grey', #3068B4) center center no-repeat;
                    background-size: 14px 14px;
                    width: 20px;
                    height: 20px;
                }
            }
        }
        input[type=checkbox] {
            position: absolute;
            cursor: pointer;
            width: 50%;
            right: 0;
            height: 40px;
            z-index: 1;
            opacity: 0;
            &:checked + label {
                //color: #F49128;
                &:after {
                    background: sassvg('ic-minus', #F9913A) center center no-repeat;
                    background-size: 14px 5px;
                }
            }
            &:checked ~ .mobile-header-nav-list-level-1 {
                opacity: 1;
                visibility: visible;
                height: auto;
            }
        }
    }
    ul.mobile-header-nav-list-level-1 {
        opacity: 0;
        visibility: hidden;
        height: 0;
        -o-transition: all 0.2s ease-out 0s;
        -ms-transition: all 0.2s ease-out 0s;
        -moz-transition: all 0.2s ease-out 0s;
        -webkit-transition: all 0.2s ease-out 0s;
        transition: all 0.2s ease-out 0s;
        li {
            padding-left: 40px;
            border-bottom: 1px solid #EAF1F6;
            position: relative;
            &.active {
                a {
                    font-weight: bold;
                    color: #f49128;
                }
                &:before {
                    content: "";
                    width: 5px;
                    height: 16px;
                    background: #f49128;
                    left: 21px;
                    top: 13px;
                    position: absolute;
                    display: block;
                }
            }
        }
        a {
            display: block;
            color: #4E5256;
            font-size: 16px;
            line-height: 40px;
            position: relative;
            @include until($ctablet+1) {
                font-size: 14px;
            }
        }
    }
}

.mobile-header-language {
    background: #EAF1F6;
    padding: 20px;
    li {
        padding-left: 40px;
        a {
            padding-left: 20px;
            line-height: 40px;
            position: relative;
            display: block;
            font-size: 16px;
            font-weight: bold;
            &.active {
                &:before {
                    content: "";
                    width: 3px;
                    height: 20px;
                    background: #f49128;
                    left: -20px;
                    top: 10px;
                    position: absolute;
                    display: block;
                }
            }
        }
        .header-language-item-link-de {
            background: url(/Images/DE.png) left center no-repeat;
        }
        .header-language-item-link-gb {
            background: url(/Images/GB.png) left center no-repeat;
        }
    }
}