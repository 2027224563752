.search-page-wrapper {
  max-width: $container-width;
  margin: 10px auto;
  padding: 0 20px;
  @include until($xdesktop){
    margin: 50px auto;
    padding-top: 30px;
  }
}
form#tx_indexedsearch {
  margin-bottom: 15px;
  min-height: 40px;
  @include mobile {
    margin-bottom: 10px;
  }
  input {
    display: block;
    float: left;
    margin: 0;
    padding: 0 10px;
    color: #4E5256;
    font-family: $font-libre;
    font-size: 13px;
    line-height: 20px;
    height: 40px;
    max-width: 300px;
    &.tx-indexedsearch-searchbox-sword {
      width: calc(100% - 100px);
      @include mobile {
        float: none;
      }
    }

    &.tx-indexedsearch-searchbox-button {
      width: 110px;
      border-radius: 0;
      background-color: #F49128;
      border: none;
      border-radius: 3px;
      color: #FFFFFF;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      text-align: center;
      cursor: pointer;
      -webkit-appearance: none;
      @include mobile {
        float: none;
        margin-top: 15px;
      }
    }
  }
}

/* milan */
.searchFor {
  display: inline-block;
  color: #3068B4;
  font-size: 24px;
  font-weight: 800;
  line-height: 28px;
  margin: 0 0 30px 0;
}

.tx-indexedsearch-res {
  padding: 25px 0px;
  .search-breadcrumb {
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      position: relative;
      margin: 0 12px 0 0;
      a {
        color: #3068B4;
        font-family: $font-libre;
        font-size: 14px;
        line-height: 24px;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      &:after {
        position: absolute;
        right: -15px;
        top: 5px;
        content: "";
        display: block;
        background: sassvg('arrow-bcd', #F49128) no-repeat center;
        width: 15px;
        height: 15px;
      }
      &.last {
        &:after {
          display: none;
        }
      }
    }
  }

  .tx-indexedsearch {
    &-title {
      color: #3068B4;
      font-size: 24px;
      font-weight: 800;
      line-height: 28px;
      margin: 0;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
      a {
        color: #3068B4;
        font-size: 24px;
        font-weight: 800;
        line-height: 28px;
      }
    }
    &-description {
      color: #4E5256;
      font-family: $font-libre;
      font-size: 16px;
      line-height: 28px;
      @include mobile {
        font-size: 14px;
        line-height: 24px;
      }
    }
    &-redMarkup {
      background-color: #F8E71C;
    }
  }

  h4 {
    margin-bottom: 5px;
  }
}

ul.tx-indexedsearch-browsebox {
  padding-left: 0;
  margin-left: 0;
  li {
    display: inline-block;
    border: 1px solid #3068B4;
    margin-right: 5px;
    margin-bottom: 6px;
    padding: 0px 5px;
    a {
      font-size: 12px;
      color: #3068B4;
    }
    &:last-child,
    &:first-child {
      border: none;
    }
    &.tx-indexedsearch-browselist-currentPage {
      border: 1px solid #3068B4;
    }
  }
}
.tx-indexedsearch-browsebox.results-out-of {
  color: #4E5256;
  font-family: $font-libre;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 20px;
  strong {
    color: #F49128;
  }
  @include mobile {
    font-size: 14px;
    line-height: 22px;
  }
}