// ----------------------------
// VARIABLES
// Content widths
$container-width: 1180px;
$container-width-mid: 960px;
$container-width-narrow: 800px;
$ce-margins: 30px auto 30px auto;
$ce-margins-large: 50px auto 50px auto;
$ce-margins-xlarge: 70px auto 70px auto;
$container-mobile-padding: 20px;
// Menu switch
$menuswitch: 1024px;
// Fonts
$font-muli: 'Muli', sans-serif;
$font-libre: 'Libre Franklin', sans-serif;

$xsiphone: 430px;

// Font colors
$title-color: #3068B4;
$text-color: #4E5256;
$text-color-light: #979797;
// Border colors
$border-lightgrey: #D7E3EF;
$border-grey: $text-color;
// Page grid widths
$sidebar-left-2-container: 200px;
$sidebar-right-2-container: 200px;
$sidebar-left-3-container: 200px;
$sidebar-right-3-container: 300px;
$sidebar-left-2-container-fluid: 230px;
$sidebar-right-2-container-fluid: 200px;
$sidebar-left-3-container-fluid: 200px;
$sidebar-right-3-container-fluid: 300px;
$content-padding: 0 20px; // Used for default page padding on mobile
$content-padding-mobile: 0 20px; // Used for default page padding on mobile
$base-p-space: 20px;
$a-link-color: #000;
$a-hover-active-color: #d00;
$a-visited-color: #666;
//Header  
$header-padding-column: 0;
$header-border: 1px solid $border-lightgrey;
$header-height: 70px;
$header-contact-color: $title-color;
$header-language-color: $text-color;
// Navigation
$nav-padding-main: 0; // Used as padding for main navigation
$nav-popup-min-width: 220px;
$nav-popup-background: #ddd;
$nav-popup-border: 1px solid #bbb;
$nav-popup-border-radius: 2px;
//Colors
$color-orange: #F49128;
$blue-background: #EAF1F6;
// Mega menu
$mega-menu-width: 740px;
$mega-menu-bg: #fff;
$mega-menu-padding-submenu: 20px 40px;
$mega-menu-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.22);
$mega-menu-active: $color-orange;
$mega-menu-height: 20px;
// Nav Links definitions Level 0
$nav-level-0-padding: 25px;
$nav-level-0-margin-side: 0;
$nav-level-0-color: $title-color;
$nav-level-0-background: #eee;
$nav-level-0-color-active: $title-color;
$nav-level-0-background-active: #ddd;
$nav-level-0-color-hover: $color-orange;
$nav-level-0-background-hover: #ccc;
$nav-level-0-border: 1px dotted #444;
$nav-level-0-border-radius: 2px;
// Nav Links definitions Level 1
$nav-level-1-padding: 5px 15px;
$nav-level-1-color: #333;
$nav-level-1-background: #eee;
$nav-level-1-color-active: #000;
$nav-level-1-background-active: #ddd;
$nav-level-1-color-hover: #000;
$nav-level-1-background-hover: #ccc;
$nav-level-1-border: 1px solid #eee;
$nav-level-1-border-radius: 3px;
// Nav Links definitions Level 2
$nav-level-2-padding: 5px 15px;
$nav-level-2-color: #333;
$nav-level-2-background: #eee;
$nav-level-2-color-active: #000;
$nav-level-2-background-active: #ddd;
$nav-level-2-color-hover: #000;
$nav-level-2-background-hover: #ccc;
$nav-level-2-border: 1px solid #eee;
$nav-level-2-border-radius: 3px;
// Mobile navigation Slideout
$slideout-padding-mobile: 0px; // Used as padding for mobile slideout
$slideout-width: 360px;
$slideout-padding: 30px;
// SECTIONS VARS
$section-width-container-narrow: $container-width-narrow;
$section-width-container-normal: $container-width;
$section-width-content-narrow: $container-width-narrow;
$section-width-content-normal: $container-width;
$section-padding-top-small: 20px;
$section-padding-top-middle: 50px;
$section-padding-top-large: 100px;
$section-padding-bottom-small: 20px;
$section-padding-bottom-middle: 50px;
$section-padding-bottom-large: 100px;
$section-margin-top-small: 20px;
$section-margin-top-middle: 50px;
$section-margin-top-large: 100px;
$section-margin-bottom-small: 20px;
$section-margin-bottom-middle: 50px;
$section-margin-bottom-large: 100px;
// Main image
$main-image-width: 1440px;
// Footer
$footer-bg: #2B5082;
$footer-color: #fff;
$footer-copyright-color: $border-lightgrey;
$footer-copyright-border: 1px solid #557FB8;
$footer-copyright-padding: 20px 0;
$footer-nav-link: $border-lightgrey;
$footer-nav-link-hover: $color-orange;
// Cookie
$cookie-bg: rgba(#000, 0.5);
$cookie-color: #fff;
$cookie-padding: 25px 0;
// Teaser background
$teaser-color: #fff;
$teaser-padding: 40px 100px;
$teaser-margin: 40px auto 40px;
$teaser-radius: 10px;
$teaser-span: $color-orange;
//Category image box
$category-image-box-bg: $blue-background;
$category-image-box-padding: 35px 0;
$category-image-box-title: $title-color;
$category-image-box-item: $text-color;
$category-image-box-link: $color-orange;
//Icon headline text
$icon-headline-text-bg: $blue-background;
$icon-headline-text-padding: 35px 0;
$icon-headline-text-title: $title-color;
$icon-headline-text-description: $text-color;
$icon-headline-text-link: #fff;
$icon-headline-text-link-bg: $color-orange;
//Package Content
$package-content-header: $text-color;
$package-content-header-highlight: $title-color;
