$blog-list--margin-ce: 30px;
$blog-list--margin-ce-large: 70px; // Used for News in list and also for Sticky nav

.blog-list {
  // Removes top margin for page-main
  @include mobile {
    margin-top: -50px;
  }
  &--container {
    &-inner { // Wrapper for news list and sidebar
      width: 100%;
      max-width: $container-width;
      margin: 0 auto;
      padding: 0 $container-mobile-padding; // Add global padding

      display: grid;

      @include from($desktop) {
        grid-template-columns: 800px 200px;
        align-items: start; // Needed for sticky sidebar
      }
      @include from($widescreen) {
        grid-template-columns: 980px 200px;
      }
      @include until($xdesktop) {
        display: block;
      }
    }
    &-breadcrumb {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 1180px;
      padding-left: 20px;
      @include until($xdesktop) {
        position: relative;
        top: -60px;
      }
    }
  }

  &--all-news {

    @include until($desktop down) {
      order: 2;
    }
    @include from($widescreen) {
      margin-left: -80px; // Move whole container to the left
    }
  }

  &--no-news {
    margin: $blog-list--margin-ce-large 0;
    font-size: 18px;
  }

  &--sidebar {

    // Maybe needed for something ...
    //@include until($desktop) {
    //  order: 1;
    //  margin-top: $blog-list--margin-ce;
    //}
    @include until($xdesktop) {
      order: 1;
      margin: $blog-list--margin-ce 0;
    }
    @include from($desktop) {
      margin: $blog-list--margin-ce-large 0;
      position: sticky;
      top: 150px;
    }

    &-search {
      margin-bottom: 30px;
      position: relative;

      &-field {
        border: none;
        border-bottom: 1px solid $border-grey;
        padding: 8px 8px 20px 30px;
        width: 100%;

        font-family: $font-libre;
        font-size: 15px;
        font-weight: 700;
        color: $text-color;

        &::placeholder {
          font-family: $font-libre;
          font-size: 15px;
          font-weight: 700;
        }

        &:focus {
          outline-width: 0;
        }
      }

      &-submit {
        position: absolute;
        display: inline-block;
        left: 0;
        top: 0;
        width: 30px;
        height: 30px;
        border: none;
        cursor: pointer;
        background: url("/themes/echolon/src/Sprite/SVG/ic-search-header.svg") 10px 10px no-repeat;

        &:focus {
          outline-width: 0;
        }
      }
      @include until($xdesktop) {
        display: none;
      }
    }

    &-categories {
      margin: 0 !important;
      padding: 0;

      &-item {
        // Move items to the right, will reset and add padding for _active state
        list-style: none;
        margin-left: 20px;
        border-bottom: 1px solid $border-lightgrey;

        &_first {
          border-top: 1px solid $border-lightgrey;
        }
      }

      &-link {
        display: block;
        padding: 3px 0 3px 0;
        margin-top: 3px;
        margin-bottom: 3px;
        font-size: 16px;
        color: $text-color-light;

        &_active {
          // Border + Padding Left = Margin left
          border-left: 6px solid $color-orange;
          margin-left: -20px;
          padding-left: 14px;
        }

        &_active,
        &:hover {
          color: $color-orange;
        }
      }
      @include until($xdesktop) {
        display: none;
      }
    }


  }

  &--dropdown {
    display: none;
    margin: 40px 0;
    @include until($xdesktop) {
      display: block;
    }
  }

  span.select2-container {
    border: 1px solid #D7E3EF;
    border-radius: 6px;
    background-color: #FFFFFF;
    width: 100%;
    line-height: 16px;
    display: block;
    width: 100% !important;
    padding: 10px 20px;
    @extend .transition;
    color: #4E5256 !important;
    font-size: 16px;
    height: auto;

    &:focus,
    &:hover {
      outline: none;
      box-shadow: 0 2px 3px 0 rgba(79, 79, 79, 0.1);
    }

    .select2-selection__arrow {
      background: sassvg('ic-select', #3068B4) center no-repeat;
      height: 50px;
      position: absolute;
      top: 1px;
      right: 15px;
      width: 20px;
      display: block;

      b {
        display: none;
      }
    }

    .selection {
      height: 35px;

      .select2-selection,
      .select2-selection__rendered {
        line-height: 35px;
        height: 35px;
        border: none;
        background: transparent;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
    padding: 0;
    color: #4E5256 !important;
  }

  .select2-results {
    ul.select2-results__options {
      margin: 0;
      padding: 0;

      li {
        margin: 0;
        padding: 0 20px;
        line-height: 35px;
        font-size: 16px;
        //font-weight: bold;
      }
    }
  }


}

// Wrapper for main news
.blog-list--main-news {
  background: $category-image-box-bg;
  position: relative; // Needed for absolute positioning of headline

  &-image-container {
    height: 300px;
    @include between($tablet, $desktop) {
      height: 450px;
    }
    @include from($desktop) {
      height: 650px;
    }
    background-size: cover !important;
    background-position: center center;
    // Set mask
    &:before {
      content: "";
      background: url(/themes/echolon/dist/Resources/Public/Backgrounds/BlogHeaderMask.svg) bottom no-repeat;
      background-size: cover;
      display: block;
      height: 100%;
    }
  }

  &-headline {
    @include blueHeadline(
                    42px,
                    32px,
                    48px,
                    40px,
                    700
    );
    padding-right: 200px;
    @include until($xdesktop){
      padding-right: 0px;
    }
    a {
      color: $title-color !important;
    }
  }

  &-description {
    margin: 10px 0;
    font-size: 18px;
    line-height: 180%;
    @include from($desktop) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &-data {
    position: relative;
    max-width: $container-width;
    margin: -50px auto 0;
    @include between($tablet, $desktop) {
      margin: -100px auto 0;
    }
    @include from($desktop) {
      margin: -150px auto 0;
    }
    padding: 0 $container-mobile-padding 50px $container-mobile-padding; // Add padding for data
    @include between($tablet, $desktop) {
      padding-right: 200px;
    }
    @include from($desktop) {
      padding-right: 500px;
    }
    @include until($xdesktop) {
      margin: -50px auto 0;
    }
  }

  &-readmore {
    @include orangeLink;

  }
}

// Wrapper for single news
.blog-list--single-news { // Wrapper for single news
  margin: $ce-margins-large 0 $ce-margins-large 0;
  position: relative;

  @include from($tablet) {
    display: grid;
    grid-template-columns: 45% 45%;
    grid-column-gap: 40px;
    margin: $blog-list--margin-ce-large 0;
  }

  @include from($widescreen) {
    grid-template-columns: 480px 480px;
  }

  &-headline {
    @include blueHeadline;
    @include from($tablet) {
      margin-top: 0 !important; // Align top of headline with image
    }

    a {
      color: $title-color !important;
    }
  }

  &-description {
    margin: 10px 0;
    line-height: 180%;
  }

  &-image-container {
    margin-left: -$container-mobile-padding; // Move image to the edges
    margin-right: -$container-mobile-padding; // Move image to the edges
    display: inline-grid;
    a {
      display: inline-grid;
    }
  }

  &-image {
    border-radius: $teaser-radius;
    @include until($tablet) {
      width: 100vw;
    }
  }

  &-readmore {
    @include orangeLink;
  }

  &-author {
    @include from($widescreen) {
      position: absolute;
      bottom: 0;
    }
  }
}

// Author
.blog-author {

  &--container {
    display: grid;
    grid-template-columns: 80px auto;
  }

  &--image {
    border-radius: $teaser-radius;
    display: inline-grid; // Remove space below the image
  }

  &--data {
    // Align items to top and bottom of left border
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: 100%; // Remove space around text to align with border

    // Add border
    padding-left: 20px;
    border-left: 5px solid $color-orange;

    font-size: 14px;
    &-category {
      text-transform: uppercase;
      padding-bottom: 5px; // Keep padding between items in case of too much text
    }

    &-name {
      padding-bottom: 5px; // Keep padding between items in case of too much text
    }

    &-date {
      color: $text-color-light;
    }

    &-category-link,
    &-name {
      font-weight: 700;
      color: inherit;
    }
  }
}

// Inherit .blog-teaser... classes from .blog-list...
.blog-teaser--single-news-image {
  @extend .blog-list--single-news-image;
}

.blog-teaser--single-news-headline {
  @extend .blog-list--single-news-headline;
}

.blog-teaser--single-news-description {
  @extend .blog-list--single-news-description;
}

.blog-teaser--single-news-readmore {
  @extend .blog-list--single-news-readmore;
}

.blog-teaser {
  &--divider {
    margin: 40px auto;
    max-width: $container-width-mid;
    border: none;
    border-bottom: 3px solid $blue-background;
  }

  &--headline {
    @include blueHeadline(
                    32px,
                    28px,
                    36px,
                    32px,
                    700
    );
    text-align: center;
    margin: 50px 0 !important;
  }

  &--container {
    &-inner {
      margin: 0 auto $blog-list--margin-ce-large;
      max-width: $container-width-mid;
      @include until($desktop) {
        padding: 0 $container-mobile-padding; // Add global padding
      }
      @include between($tablet, $desktop) {
        display: grid;
        grid-template-columns: 45% 45%;
        grid-column-gap: 10%;
      }
      @include from($desktop) {
        display: grid;
        grid-template-columns: 430px 430px;
        grid-column-gap: 100px;
      }
    }
  }

  &--single-news {
    margin: $ce-margins-large 0 $ce-margins-large 0;
    position: relative;

    &-image-container {
      @include until($desktop) {
        margin-left: -$container-mobile-padding; // Move image to the edges
        margin-right: -$container-mobile-padding; // Move image to the edges
      }
      @include from($desktop) {
        padding-bottom: 20px;
      }
    }
  }
}