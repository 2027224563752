/* libre-franklin-regular - latin-ext_latin */
@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('/themes/echolon/fonts/libre-franklin-v12-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/themes/echolon/fonts/libre-franklin-v12-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
/* libre-franklin-700 - latin-ext_latin */
@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('/themes/echolon/fonts/libre-franklin-v12-latin-ext_latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/themes/echolon/fonts/libre-franklin-v12-latin-ext_latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
/* libre-franklin-italic - latin-ext_latin */
@font-face {
  font-family: 'Libre Franklin';
  font-style: italic;
  font-weight: 400;
  src: local(''),
       url('/themes/echolon/fonts/libre-franklin-v12-latin-ext_latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/themes/echolon/fonts/libre-franklin-v12-latin-ext_latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}




/* mulish-regular - latin-ext_latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('/themes/echolon/fonts/mulish-v11-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/themes/echolon/fonts/mulish-v11-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
/* mulish-600 - latin-ext_latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 600;
  src: local(''),
       url('/themes/echolon/fonts/mulish-v11-latin-ext_latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/themes/echolon/fonts/mulish-v11-latin-ext_latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
/* mulish-700 - latin-ext_latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('/themes/echolon/fonts/mulish-v11-latin-ext_latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/themes/echolon/fonts/mulish-v11-latin-ext_latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
/* mulish-800 - latin-ext_latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 800;
  src: local(''),
       url('/themes/echolon/fonts/mulish-v11-latin-ext_latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/themes/echolon/fonts/mulish-v11-latin-ext_latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}


///* rubik-regular - latin-ext_latin */
//@font-face {
//  font-family: 'Rubik';
//  font-style: normal;
//  font-weight: 400;
//  src: local(''),
//       url('/themes/echolon/fonts/rubik-v20-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
//       url('/themes/echolon/fonts/rubik-v20-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
//}
///* rubik-500 - latin-ext_latin */
//@font-face {
//  font-family: 'Rubik';
//  font-style: normal;
//  font-weight: 500;
//  src: local(''),
//       url('/themes/echolon/fonts/rubik-v20-latin-ext_latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
//       url('/themes/echolon/fonts/rubik-v20-latin-ext_latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
//}
///* rubik-700 - latin-ext_latin */
//@font-face {
//  font-family: 'Rubik';
//  font-style: normal;
//  font-weight: 700;
//  src: local(''),
//       url('/themes/echolon/fonts/rubik-v20-latin-ext_latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
//       url('/themes/echolon/fonts/rubik-v20-latin-ext_latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
//}