@mixin blockquote() {
  display: block;
  margin: 30px 0;
  padding-left: 55px;
  border-left: 5px solid #3068B4;
  color: #4E5256;
  font-family: "Libre Franklin", sans-serif;
  font-size: 16px;
  font-style: italic;
}

@mixin text() {
  font-family: $font-libre;
  color: $text-color;
  font-size: 16px;
  line-height: 28px;
}

@mixin title() {
  color: $title-color !important; // bulma overrides
  font-family: $font-muli !important; // bulma overrides
  font-size: 32px !important; // bulma overrides
  font-weight: 800 !important; // bulma overrides
  line-height: 38px !important; // bulma overrides
}

@mixin h3() {
  color: $text-color;
  font-family: $font-muli;
  font-size: 20px;
  font-weight: 800;
  line-height: 28px;
  margin: 25px 0 15px 0;
}

$ctablet: 768px;
$xdesktop: 1024px;
$cmobile: 640px;
@mixin between($device1, $device2) {
  @media screen and (min-width: $device1) and (max-width: $device2) {
    @content
  }
}

@mixin orangeLink() {
  text-decoration: underline;
  font-family: $font-muli;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin-top: 15px;
  margin-bottom: 25px;
  display: inline-block;
  color: $color-orange;

  &:hover {
    text-decoration: none;
    color: $color-orange;
  }
}

@mixin blueHeadline(
  $font-size: 28px,
  $font-size-mobile: 26px,
  $line-height: 35px,
  $line-height-mobile: 30px,
  $font-weight: 800
) {
  margin: 15px 0 15px !important;
  font-family: $font-muli;
  color: $title-color !important;
  font-size: $font-size !important;
  font-weight: $font-weight !important;
  line-height: $line-height !important;
  @include mobile {
    font-size: $font-size-mobile !important;
    line-height: $line-height-mobile !important;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}