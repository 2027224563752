// HEADER SETTINGS
// Change style of header items here
// Do not change main navigation here!
body {
    font-family: $font-libre;
}

a {
    @extend .transition;
}

strong {
    color: $text-color;
}

.clr {
    clear: both;
}

.columns {
    @include mobile {
        margin: 0 !important;
    }
}
.page-main {
    @include mobile {
        margin-top: 50px;
    }
}

.header-container-inner {
    // Used only on desktop
    width: 100%; // Default width, change media query below if needed
    @include from($widescreen) {
        width: $container-width; // Set with to container width
    }
    margin: 0 auto;
    align-items: center; // Align items vertically
    height: 70px;
    margin-bottom: 0;
    @include until($xdesktop) {
        height: 50px !important;
    }
    .column {
        line-height: 20px;
        display: flex;
        align-items: center;
        height: $header-height;
        font-size: 14px;
        font-weight: bold;
        font-family: $font-muli;
        padding: $header-padding-column;
        border-right: $header-border;
        margin: 0 !important;
        @include until($xdesktop) {
            height: 50px !important;
            font-size: 14px;
        }
        @include until($tablet) {
            font-size: 13px;
        }
    }
}

.header-container {
    width: $container-width;
    margin: 0 auto;
    display: block;
}

.header-container-wrapper {
    border-bottom: $header-border;
    @include until($xdesktop) {
        padding-right: 67px;
    }
    // Used for iPhone small | < 430px
    @include until($xsiphone) {
        padding-right: 47px;
    }
}

.header-container-m {
    background: #fff;
    max-height: 100vh;
    overflow-y: scroll;
    z-index: 9998;
}

// Set header items to columns only above menu switch
.header-logo {
    padding: 0;
    border-right: none !important;
    @include mobile {
        //flex: 1 1 170px;
        flex: 1 1 100px;
        img {
            height: 30px;
            margin: 10px 0 0 10px;
        }
    }
    @include tablet-only {
        img {
            margin-left: 20px;
        }
    }
    @include until(480px) {
        max-width: 160px;
    }
    // Used for iPhone small | < 430px
    @include until($xsiphone) {
        a {
            margin-right: 10px;
        }
        img {
            width: 100% !important;
            margin: 10px 10px 0 10px;
            max-width: none !important;
        }
    }
}

.header-right {
    max-width: 680px;
    @include desktop {
        flex: 1 1 200px;
    }
    @include until($xdesktop) {
        max-width: 290px;
    }
    @include until($tablet+1) {
        max-width: 240px;
    }
    // Used for iPhone small | < 430px
    @include until($xsiphone) {
        max-width: 210px;
    }
}

.header-contact-wrapper {
    @include mobile {
        a {
            margin: auto;
        }
    }
    .header-contact {
        color: $header-contact-color;
        background: sassvg('ic-phone-header', $header-contact-color) 25px center no-repeat;
        padding-left: 50px !important;
        width: 200px;
        a {
            color: $header-contact-color;
        }
        @include until($xdesktop) {
            max-width: 60px;
            height: 40px;
            &.hide-mobile {
                max-width: 0;
                overflow: hidden;
            }
        }
        @include mobile {
            max-width: 40px;
            padding-left: 40px !important;
            background-position: 15px center;
            a {
                margin: auto;
            }
        }
        @include until($cmobile){
            height: 40px;
        }
    }
}

.header-search {
    position: relative;
    background: url("/themes/echolon/src/Sprite/SVG/ic-search-header.svg") 25px center no-repeat;
    padding-left: 45px !important;
    width: 125px;
    border-right: none !important;
    transition: none;
    &.expanded {
        input {
            width: 120px;
        }
        @include until($xdesktop){
            input {
                width: 190px;
            }
        }
        @include until($tablet+1) {
            background: none;
            width: 175px;
            max-width: 175px !important;
            background: none;
            input {
                width: 160px;
            }
        }
        // Used for iPhone small | < 430px
        @include until($xsiphone) {
            input {
                width: 50px;
            }
        }
    }
    @include until($xdesktop) {
        max-width: 179px;
    }
    @include until($tablet+1) {
        max-width: 70px !important;
        border-right: none !important;
        margin-right: 40px !important;
        padding-left: 0px !important;
        background: url("/themes/echolon/src/Sprite/SVG/ic-search-header.svg") 25px center no-repeat;
        filter: hue-rotate(-10deg) saturate(20);
    }
    // Used for iPhone small | < 430px
    @include until($xsiphone) {
        background-position: center;
        width: 40px;
    }
    input {
        background: none;
        border: none;
        box-shadow: none !important;
        width: 50px;
        padding: 0;
        font-family: $font-muli;
        @include until($tablet+1) {
            margin: 0 9px;
            width: 40px;
        }
        // Used for iPhone small | < 430px
        @include until($xsiphone) {
            width: 40px;
        }
        &::-webkit-input-placeholder {
            color: $header-language-color;
            opacity: 1;
            font-size: 14px;
            font-weight: bold;
        }
        &::-moz-placeholder {
            color: $header-language-color;
            opacity: 1;
            font-size: 14px;
            font-weight: bold;
        }
        &::-ms-input-placeholder {
            color: $header-language-color;
            opacity: 1;
            font-size: 14px;
            font-weight: bold;
        }
        &::-moz-placeholder {
            color: $header-language-color;
            opacity: 1;
            font-size: 14px;
            font-weight: bold;
        }
        &:focus {
            outline: none;
            border: none;
            box-shadow: none !important;
        }
    }
}

.header-language {
    background-size: 16px;
    width: 90px;
    @extend .transition;
    &.collapse {
        width: 65px;
        .header-language-item-name {
            display: none;
        }
    }
    .header-language-item-name {
        display: inline-block;
        @extend .transition;
    }
    @include mobile {
        display: none;
    }
    &-item-link {
        color: $header-language-color;
    }
    &-item {
        display: none;
        height: 20px;
        padding-left: 45px;
        padding-right: 25px;
        &.de {
            background: url("/themes/echolon/src/Sprite/SVG/ic-german-flag-color.svg") 25px center no-repeat;
            background-size: 16px;
        }
        &.en {
            background: url("/themes/echolon/src/Sprite/SVG/ic-uk-flag-color.svg") 25px center no-repeat;
            background-size: 16px;
        }
        &.language-select {
            display: block;
        }
    }
}

.header-email {
    width: 235px;
    .main-image-link {
        margin: auto;
    }
    a {
        font-size: 16px !important;
        @include until($desktop) {
            font-weight: bold !important;
            font-size: 15px !important;
            width: auto !important;
            margin: 0 10px !important;
        }
    }

    @include until($xdesktop) {
        width: 115px;
    }

}

.page-footer {
    background: sassvg('footer-bck', #325689) left center no-repeat $footer-bg;
    position: relative;
    @include until($desktop){
        background-size: cover;
    }
}

.footer {
    &-container {
        width: $container-width;
        max-width: 100vw;
        margin: 0 auto;
        color: $footer-color;
        position: relative;
        &-inner {
            padding: 50px 20px 20px 20px;
        }
    }
    &-info {
        font-size: 12px;
        color: #EAF1F6;
        max-width: 350px;
        h4 {
            color: $footer-nav-link !important;
            margin-bottom: 25px !important;
            font-weight: bold;
            margin-bottom: 25px;
        }
        p {
            margin-bottom: 0px;
            color: #EAF1F6;
            &.footer-nav-phone {
                color: #FFFFFF;
                font-family: $font-muli;
                font-size: 16px;
                font-weight: 800;
                position: relative;
                margin-left: 25px;
                &:after {
                    content: "";
                    background: sassvg('ic-phone-header', #F49128 ) center center no-repeat;
                    width: 16px;
                    height: 19px;
                    position: absolute;
                    left: -25px;
                    top: 5px;
                    display: block;
                }
                @include until($tablet+1) {
                    position: relative;
                    top: unset;
                    left: unset;
                    margin-left: 27px;
                    margin-top: 20px;
                }
            }
        }
        a {
            color: #EAF1F6;
            text-decoration: underline;
            margin-bottom: 25px;
            display: inline-block;
            &:hover {
                text-decoration: none;
            }
        }

        img {
            margin-bottom: 55px;
            @include until($tablet+1) {
                margin-bottom: 20px;
            }
        }
    }
    &-copyright {
        margin-top: 50px;
        border-top: $footer-copyright-border;
        color: rgba($footer-copyright-color, 0.5);
        font-family: $font-libre;
        font-size: 10px;
        line-height: 15px;
        padding: $footer-copyright-padding;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 70% auto;
        .fNav {
            text-align: right;
            a {
                display: inline-block;
                margin: 0 10px;
                font-size: 10px;
                line-height: 15px;
            }
        }
        @include mobile {
            grid-template-columns: auto;
            .fCopy {
                order: 2;
            }
            .fNav {
                order: 1;
                text-align: center;
            }
            p {
                text-align: center;
                padding: 0 20px;
            }
        }
    }
    &-nav {
        display: grid;
        grid-gap: 40px;
        grid-template-columns: 300px 1fr;
        justify-content: space-between;
        @include mobile {
            grid-template-columns: 1fr;
            grid-gap: 60px;
        }
        &-list-level-0 {
            list-style: none !important;
            margin: 0 !important;
        }
        &-link-level-0 {
            color: $footer-nav-link;
            font-size: 12px;
            display: block;
            position: relative;
            line-height: 23px;
            &:hover {
                color: $footer-nav-link-hover;
            }
            &.active {
                color: $footer-nav-link-hover;
                &:before {
                    position: absolute;
                    content: "";
                    width: 6px;
                    left: -11px;
                    height: 17px;
                    top: 3px;
                    background: $footer-nav-link-hover;
                }
            }
        }
        &-title {
            color: $footer-nav-link !important;
            font-family: $font-libre !important;
            font-size: 12px !important;
            margin-bottom: 25px;
            font-weight: bold;
            line-height: 28px;
            text-transform: uppercase;
        }
    }
    &-sections-nav {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 40px;
        @include until($xdesktop){
            grid-template-columns: 1fr 1fr;
            grid-gap: 40px 10px;
        }
        @include until($cmobile){
            grid-template-columns: 1fr;
        }
    }
}

.cookie-notice__container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: $cookie-bg;
    color: $cookie-color;
    max-width: 100vw;
    &-inner {
        width: $container-width;
        margin: 0 auto;
        padding: $cookie-padding;
        max-width: 100vw;
    }
}

// Override Width for two cols
.page-content-right-2 {
    .gif-box-container,
    .video-box-container,
    .image-mapping-container,
    .text-onecol-container,
    .one-image-container,
    .bullet-list-container,
    .accordion-container,
    .contact-box-small-container,
    .download-box-container,
    .software-box-large2-container,
    .text-twocol-container,
    .link-box-container,
    .text-img-circle-two-cols-container,
    .advantage-list-container,
    .package-content-container,
    .arrows-graphic2-container,
    .blue-circles-container,
    .box-icon2-container,
    .box-icon3-container,
    .text-img-large-container,
    .img-description-container,
    .blue-box-container,
    .text-img-circle-container,
    .blue-divider-container,
    .training-box-container,
    .form-default-container,
    .frame-default > header > h2,
    .partner-box1-container,
    .partner-box2-container,
    .tabs-container,
    .quotes-container,
    .contact-bar-container {
        padding-left: 300px;
        @include until($desktop) {
            padding: 0px 20px;
        }
    }

    // Reset container-width
    .image-mapping-container,
    .text-onecol-container,
    .text-twocol-container,
    .accordion-container,
    .bullet-list-container,
    .arrows-graphic2-container {
        max-width: $container-width;
    }
}

.page-content-right-2 > :first-child {
    margin-top: 0;
}

.image-mapping,
.accordion,
.advantage-list,
.arrows-graphic,
.arrows-graphic2,
.blue-box,
.blue-circles,
//.blue-divider,
.box-icon,
.box-icon2,
.box-icon3,
.bullet-list,
.category-image-box,
.contact-box-small,
.download-box,
.form-default,
.headline-text-image,
.icon-headline-text,
.img-description,
.link-box,
.news-box-h,
.news-box-small,
.news-box-small1,
.news-box-v,
.onecol,
.one-image,
.package-content,
.partner-box1,
.partner-box2,
.pricing-box,
.quotes,
.reference-large,
.reference-small,
.slider-logo,
.software-box-large,
.software-box-large2,
.software-box-small,
.table-ce,
.tabs,
.teaser-background,
.testimonials,
.text,
.text-img-circle,
.text-img-circle-two-cols,
.text-img-large,
.text-onecol,
.text-twocol,
.threecols,
.training-box,
.twocols,
.contact-bar,
.gif-box,
.video-box {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
}




.accordion.ce-background-blue,
.advantage-list.ce-background-blue,
.arrows-graphic.ce-background-blue,
.arrows-graphic2.ce-background-blue,
.blue-box.ce-background-blue,
.blue-circles.ce-background-blue,
.box-icon.ce-background-blue,
.box-icon2.ce-background-blue,
.box-icon3.ce-background-blue,
.bullet-list.ce-background-blue,
.category-image-box.ce-background-blue,
.contact-bar.ce-background-blue,
.contact-box-small.ce-background-blue,
.download-box.ce-background-blue,
.form-default.ce-background-blue,
.headline-text-image.ce-background-blue,
.icon-headline-text.ce-background-blue,
.img-description.ce-background-blue,
.link-box.ce-background-blue,
.main-image.ce-background-blue,
.main-image-news.ce-background-blue,
.main-image-reference.ce-background-blue,
.main-image-small.ce-background-blue,
.news-box-v.ce-background-blue,
.news-box-h.ce-background-blue,
.news-box-small.ce-background-blue,
.news-box-small1.ce-background-blue,
.onecol.ce-background-blue,
.one-image.ce-background-blue,
.package-content.ce-background-blue,
.partner-box1.ce-background-blue,
.partner-box2.ce-background-blue,
.pricing-box.ce-background-blue,
.quotes.ce-background-blue,
.reference-large.ce-background-blue,
.reference-small.ce-background-blue,
.slider-logo.ce-background-blue,
.software-box-large.ce-background-blue,
.software-box-large2.ce-background-blue,
.software-box-small.ce-background-blue,
.table-ce.ce-background-blue,
.tabs.ce-background-blue,
.teaser-background.ce-background-blue,
.testimonials.ce-background-blue,
.text.ce-background-blue,
.text-img-circle.ce-background-blue,
.text-img-circle-two-cols.ce-background-blue,
.text-img-large.ce-background-blue,
.text-onecol.ce-background-blue,
.text-twocol.ce-background-blue,
.threecols.ce-background-blue,
.training-box.ce-background-blue,
.twocols.ce-background-blue,
.training-box.ce-background-blue,
.gif-box.ce-background-blue,
.video-box.ce-background-blue {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: $blue-background !important;
}
.form-default {
    .form-default-contact-form {
        margin-left: 0 !important;
    }
}

.news-box-v.ce-background-blue,
.bullet-list.ce-background-blue,
.table-ce.ce-background-blue,
.contact-bar.ce-background-blue,
.link-box.ce-background-blue {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: $blue-background !important;
}