body {
    font-size: $size-6;
}

.content {
    h1, .h1 {
        font-size: $size-1;
    }
    h2, .h2 {
        font-size: $size-2;
    }
    h3, .h3 {
        font-size: $size-3;
    }
    h4, .h4 {
        font-size: $size-4;
    }
}

@include from($tablet) {
    // Width container narrow is 100% wide from tablet
    .width-container-narrow {
        max-width: $section-width-container-narrow;
        margin: 0 auto;
    }
}

@include from($desktop) {
    // Width containers are 100% wide from desktop
    .width-container-normal {
        max-width: $section-width-container-normal;
        margin: 0 auto;
    }
    .width-container-full {
        width: 100%;
    }
    .width-narrow {
        max-width: $section-width-content-narrow;
        margin: 0 auto;
    }
    .width-normal {
        width: $section-width-content-normal;
        margin: 0 auto;
    }
    .width-full {
        width: 100%;
    }
}

.page-main-container-inner,
.page-main-container-inner-fluid {
}

.page-main-container-inner {
    width: $container-width;
    margin: 0 auto;
}

// Page column definition for pages with FIXED widths
.page-main-container-inner {
    .page-sidebar-left-2 {
        width: $sidebar-left-2-container;
        margin-right: 30px;
    }
    .page-sidebar-right-2 {
        width: $sidebar-right-2-container;
    }
    .page-sidebar-left-3 {
        width: $sidebar-left-3-container;
    }
    .page-sidebar-right-3 {
        width: $sidebar-right-3-container;
    }
    .page-content-right-2 {
        max-width: 890px !important;
    }
}

// Page column definition for pages with FLUID widths
.page-main-container-inner-fluid {
    .page-sidebar-left-2 {
        width: $container-width;//$sidebar-left-2-container-fluid;
        //max-width: 1180px;
        margin: 0 auto;
        padding-right:$container-width - $sidebar-left-2-container-fluid;
        position: sticky;
        top: 150px;
        pointer-events: none;
        z-index: 1;
        @include until($desktop) {
            display: none;
        }
    }
    .page-sidebar-right-2 {
        width: $sidebar-right-2-container-fluid;
    }
    .page-sidebar-left-3 {
        width: $sidebar-left-3-container-fluid;
    }
    .page-sidebar-right-3 {
        width: $sidebar-right-3-container-fluid;
    }
}

// Define section paddings and margins, selectable via TYPO3 Backend
// Change settings for mobile here if needed (add media query)
.padding-top-small {
    padding-top: $section-padding-top-small;
}

.padding-top-middle {
    padding-top: $section-padding-top-middle;
}

.padding-top-large {
    padding-top: $section-padding-top-large;
}

.padding-bottom-small {
    padding-bottom: $section-padding-bottom-small;
}

.padding-bottom-middle {
    padding-bottom: $section-padding-bottom-middle;
}

.padding-bottom-large {
    padding-bottom: $section-padding-bottom-large;
}

.margin-top-small {
    margin-top: $section-margin-top-small;
}

.margin-top-middle {
    margin-top: $section-margin-top-middle;
}

.margin-top-large {
    margin-top: $section-margin-top-large;
}

.margin-bottom-small {
    margin-bottom: $section-margin-bottom-small;
}

.margin-bottom-middle {
    margin-bottom: $section-margin-bottom-middle;
}

.margin-bottom-large {
    margin-bottom: $section-margin-bottom-large;
}

// TYPO3 Wrapper, usually no need to change them
.ce-align-left {
    text-align: left;
}

.ce-align-center {
    text-align: center;
}

.ce-align-right {
    text-align: right;
}

.ce-textpic, .ce-image, .ce-nowrap .ce-bodytext, .ce-gallery, .ce-row, .ce-uploads li, .ce-uploads div {
    overflow: hidden;
}

.ce-left .ce-gallery, .ce-column {
    float: left;
}

.ce-center .ce-outer {
    position: relative;
    float: right;
    right: 50%;
}

.ce-center .ce-inner {
    position: relative;
    float: right;
    right: -50%;
}

.ce-right .ce-gallery {
    float: right;
}

.ce-gallery figure {
    display: table;
    margin: 0;
}

.ce-gallery figcaption {
    display: table-caption;
    caption-side: bottom;
}

.ce-gallery img {
    display: block;
}

.ce-gallery iframe {
    border-width: 0;
}

.ce-border img, .ce-border iframe {
    @extend hr;
}

.ce-intext.ce-right .ce-gallery, .ce-intext.ce-left .ce-gallery, .ce-above .ce-gallery {
    margin-bottom: $base-p-space;
}

.ce-intext.ce-right .ce-gallery {
    margin-left: 10px;
}

.ce-intext.ce-left .ce-gallery {
    margin-right: 10px;
}

.ce-below .ce-gallery {
    margin-top: $base-p-space;
}

.ce-column {
    margin-right: 10px;
}

.ce-column:last-child {
    margin-right: 0;
}

.ce-row {
    margin-bottom: $base-p-space;
}

.ce-row:last-child {
    margin-bottom: 0;
}

.ce-above .ce-bodytext {
    clear: both;
}

.ce-intext.ce-left ol, .ce-intext.ce-left ul {
    padding-left: 40px;
    overflow: auto;
}

.frame-ruler-before:before {
    content: '';
    display: block;
    border-top: 1px solid $black;
    margin-bottom: $base-p-space;
}

.frame-ruler-after:after {
    content: '';
    display: block;
    border-bottom: 1px solid $black;
    margin-top: $base-p-space;
}

.frame-indent {
    margin-left: 15%;
    margin-right: 15%;
}

.frame-indent-left {
    margin-left: 33%;
}

.frame-indent-right {
    margin-right: 33%;
}

.frame-default > header > h2 {
    margin: 30px auto 10px;
    max-width: 1180px;
    color: #3068B4;
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
}

#screen-size-debugger {
    opacity: 0.4;
    position: fixed;
    bottom:0;
    right: 0;
    background: black;
    color: white;
    padding: 3px;
    font-size:10px;
    &:hover {
        opacity: 1;
    }
    span {
        display: none;
        padding:2px 5px;
    }
    .tablet {
        @include from($tablet) {
            display: inline;
        }
    }
    .desktop {
        @include from($desktop) {
            display: inline;
        }
    }
    .xdesktop {
        @include from($xdesktop) {
            display: inline;
        }
    }
    .widescreen {
        @include from($widescreen) {
            display: inline;
        }
    }
    .fullhd {
        @include from($fullhd) {
            display: inline;
        }
    }
}


.cc-window{opacity:1;-webkit-transition:opacity 1s ease;transition:opacity 1s ease}.cc-window.cc-invisible{opacity:0}.cc-animate.cc-revoke{-webkit-transition:transform 1s ease;-webkit-transition:-webkit-transform 1s ease;transition:-webkit-transform 1s ease;transition:transform 1s ease;transition:transform 1s ease,-webkit-transform 1s ease}.cc-animate.cc-revoke.cc-top{-webkit-transform:translateY(-2em);transform:translateY(-2em)}.cc-animate.cc-revoke.cc-bottom{-webkit-transform:translateY(2em);transform:translateY(2em)}.cc-animate.cc-revoke.cc-active.cc-top{-webkit-transform:translateY(0);transform:translateY(0)}.cc-animate.cc-revoke.cc-active.cc-bottom{-webkit-transform:translateY(0);transform:translateY(0)}.cc-revoke:hover{-webkit-transform:translateY(0);transform:translateY(0)}.cc-grower{max-height:0;overflow:hidden;-webkit-transition:max-height 1s;transition:max-height 1s}
.cc-revoke,.cc-window{position:fixed;overflow:hidden;-webkit-box-sizing:border-box;box-sizing:border-box;font-family:Helvetica,Calibri,Arial,sans-serif;font-size:14px;line-height:1.5em;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:nowrap;flex-wrap:nowrap;z-index:9999}.cc-window.cc-static{position:static}.cc-window.cc-floating{padding:2em;max-width:24em;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.cc-window.cc-banner{padding:1em 1.8em;width:100%;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row}.cc-revoke{padding:.5em}.cc-revoke:hover{text-decoration:underline}.cc-header{font-size:18px;font-weight:700}.cc-btn,.cc-close,.cc-link,.cc-revoke{cursor:pointer}.cc-link{display:inline-block;padding:.2em;text-decoration:underline;color: #f49128 !important;}.cc-link:hover{text-decoration: none;}.cc-link:active,.cc-link:visited{color:initial}.cc-btn{display:block;padding:.4em .8em;font-size:.9em;font-weight:700;border-width:2px;border-style:solid;text-align:center;white-space:nowrap}.cc-highlight .cc-btn:first-child{background-color:transparent;border-color:transparent}.cc-highlight .cc-btn:first-child:focus,.cc-highlight .cc-btn:first-child:hover{background-color:transparent;text-decoration:underline}.cc-close{display:block;position:absolute;top:.5em;right:.5em;font-size:1.6em;opacity:.9;line-height:.75}.cc-close:focus,.cc-close:hover{opacity:1}
.cc-revoke.cc-top{top:0;left:3em;border-bottom-left-radius:.5em;border-bottom-right-radius:.5em}.cc-revoke.cc-bottom{bottom:0;left:3em;border-top-left-radius:.5em;border-top-right-radius:.5em}.cc-revoke.cc-left{left:3em;right:unset}.cc-revoke.cc-right{right:3em;left:unset}.cc-top{top:1em}.cc-left{left:1em}.cc-right{right:1em}.cc-bottom{bottom:1em}.cc-floating>.cc-link{margin-bottom:1em}.cc-floating .cc-message{display:block;margin-bottom:1em}.cc-window.cc-floating .cc-compliance{-webkit-box-flex:1;-ms-flex:1 0 auto;flex:1 0 auto}.cc-window.cc-banner{-webkit-box-align:center;-ms-flex-align:center;align-items:center}.cc-banner.cc-top{left:0;right:0;top:0}.cc-banner.cc-bottom{left:0;right:0;bottom:0}.cc-banner .cc-message{display:block;-webkit-box-flex:1;-ms-flex:1 1 auto;flex:1 1 auto;max-width:100%;margin-right:1em}.cc-compliance{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-ms-flex-line-pack:justify;align-content:space-between}.cc-floating .cc-compliance>.cc-btn{-webkit-box-flex:1;-ms-flex:1;flex:1}.cc-btn+.cc-btn{margin-left:.5em}
@media print{.cc-revoke,.cc-window{display:none}}@media screen and (max-width:900px){.cc-btn{white-space:normal}}@media screen and (max-width:414px) and (orientation:portrait),screen and (max-width:736px) and (orientation:landscape){.cc-window.cc-top{top:0}.cc-window.cc-bottom{bottom:0}.cc-window.cc-banner,.cc-window.cc-floating,.cc-window.cc-left,.cc-window.cc-right{left:0;right:0}.cc-window.cc-banner{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.cc-window.cc-banner .cc-compliance{-webkit-box-flex:1;-ms-flex:1 1 auto;flex:1 1 auto}.cc-window.cc-floating{max-width:none}.cc-window .cc-message{margin-bottom:1em}.cc-window.cc-banner{-webkit-box-align:unset;-ms-flex-align:unset;align-items:unset}.cc-window.cc-banner .cc-message{margin-right:0}}
.cc-floating.cc-theme-classic{padding:1.2em;border-radius:5px; text-align: center;}.cc-floating.cc-type-info.cc-theme-classic .cc-compliance{text-align:center;display:inline;-webkit-box-flex:0;-ms-flex:none;flex:none}.cc-theme-classic .cc-btn{border-radius:5px}.cc-theme-classic .cc-btn:last-child{min-width:140px}.cc-floating.cc-type-info.cc-theme-classic .cc-btn{display:inline-block}
.cc-theme-edgeless.cc-window{padding:0}.cc-floating.cc-theme-edgeless .cc-message{margin:2em;margin-bottom:1.5em}.cc-banner.cc-theme-edgeless .cc-btn{margin:0;padding:.8em 1.8em;height:100%}.cc-banner.cc-theme-edgeless .cc-message{margin-left:1em}.cc-floating.cc-theme-edgeless .cc-btn+.cc-btn{margin-left:0}
.cc-btn.cc-dismiss {
    color: #ffffff !important;
    border: 1px solid #f49128 !important;
    transition: 0.2s all !important;
    &:hover {
        background-color: #fff !important;
        color: #f49128 !important;
    }
}

.is-flexible {
    display: flex;
}