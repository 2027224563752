// MAIN DESKTOP NAVIGATION SETTINGS
// Change DESKTOP navigation here
// Do not change mobile navigation settings here!
// All items here must be wrapped by .header-container
.header-container {
    // Deactivate on mobile
    // All other settings in _m-pageheader
    @include until($menuswitch) {
        display: none;
    }
    &-wide {
        width: 100%;
    }
    &-inner {
        margin: 0 auto !important;
    }
}

.header-nav {
    width: 100%;
    margin: 0 auto;
    @include from($widescreen) {
        width: $container-width;
    }
    @include until($xdesktop) {
        display: none;
    }
    // Complete Nav padding
    padding: $nav-padding-main;
    a {
        text-decoration: none;
    }
    &-item-level-0 {
        position: relative;
    }
    &-list-level-0 {
        @include from($tablet) {
            display: flex;
        }
    }
    .header-nav-mega-menu {
        // Hides, formats and positions subnav box
        opacity: 0;
        visibility: hidden;
        position: absolute;
        display: none;
        left: 0;
        top: auto;
        border-radius: 0 0 2px 2px;
        background-color: $mega-menu-bg;
        box-shadow: $mega-menu-shadow;
        width: $mega-menu-width;
        &.pos-right {
            left: unset;
            right: 0;
        }
        //&.last-size-box {
        //    width: 630px;
        //}
        .column {
            &.last-size-img {
                width: 460px;
            }
            &.last-size-nav {
                width: 280px;
            }
            &.default-size-nav {
                width: 280px;
            }
            &.default-size-img {
                width: 460px;
            }
        }
        &.ul-two-columns {
            width: 740px;
            @mixin between($xdesktop, $widescreen){
                width: 570px;
                border: 5px solid red;
            }
            & > .column {
                width: 100%;
            }
            .header-nav-list-level-1 {
                column-count: 2;
                column-gap: 80px;
                column-rule: 1px solid #EAF1F6;
                width: 100%;
            }
        }

    }
    // Activation on hover
    &-item-level-0:hover {
        .header-nav-mega-menu {
            // Activates subnav box
            display: flex;
            opacity: 1;
            visibility: visible;
            margin: 0;
            z-index: 666666666;
            .column {
                //align-items: center;
                display: flex;
            }
            ul {
                width: 100%;
                padding: $mega-menu-padding-submenu;
            }
            li {
                padding: 0;
                a {
                    background: none;
                    padding: 5px 0 !important;
                    line-height: $mega-menu-height;
                    font-size: 14px;
                    &.active {
                        color: $mega-menu-active;
                        font-weight: 600 !important;
                    }
                    &:hover {
                        color: $mega-menu-active;
                        font-weight: 600 !important;
                    }
                }
            }
            img {
                display: block;
                margin: 0;
            }
        }
    }
    // Nav popup formatting

    // Link formatting
    &-link-level-0 {
        display: flex;
        margin: 0;
        padding: 0;
        a,
        &:link,
        &:visited {
            color: $nav-level-0-color;
            background: none;
            height: 50px;
            font-family: Muli;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            padding: 15px 25px;
            position: relative;
        }
        &:active,
        &-active {
            @extend .header-nav-link-level-0;
            color: $nav-level-0-color-hover !important;
            background: $nav-level-0-background-active;
            &:before {
                position: absolute;
                content: "";
                width: 6px;
                left: 5px;
                height: 20px;
                top: 15px;
                background: $nav-level-0-color-hover;
            }
        }
        &:hover {
            color: $nav-level-0-color-hover;
            &:before {
                position: absolute;
                content: "";
                width: 6px;
                left: 5px;
                height: 20px;
                top: 15px;
                background: $nav-level-0-color-hover;
            }
        }
    }
    &-link-level-1 {
        display: block;
        padding: $nav-level-1-padding;
        background: $nav-level-1-background;
        &:link,
        &:visited {
            color: $nav-level-1-color;
        }
        &:active,
        &-active {
            @extend .header-nav-link-level-1;
            color: $nav-level-1-color-active;
            background: $nav-level-1-background-active;
        }
        &:hover {
            color: $nav-level-1-color-hover;
            background: $nav-level-1-background-hover;
        }
    }
    &-link-level-2 {
        display: block;
        padding: $nav-level-2-padding;
        background: $nav-level-2-background;
        &:link,
        &:visited {
            color: $nav-level-2-color;
        }
        &:active,
        &-active {
            @extend .header-nav-link-level-2;
            color: $nav-level-2-color-active;
            background: $nav-level-2-background-active;
        }
        &:hover {
            color: $nav-level-2-color-hover;
            background: $nav-level-2-background-hover;
        }
    }
    > a {
        position: relative;
        padding-right: 50px;
    }

}
.is-hidden-mobile {
    @include until($xdesktop) {
        display: none !important;
    }
}
header.page-header {
    @include until($xdesktop) {
        position: fixed;
        background: #fff;
        z-index: 9999;
        width: 100vw;
        top: 0;
        left: 0;
    }
}

//Sub nav
.side-nav {
    font-weight: 400;
    border-bottom: 1px solid #D7E3EF;
    pointer-events: auto;
    ul,
    li {
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-family: $font-libre;
        &:before {
            content: '';
        }
    }
    &-list {
        &-toggle-0 {
            position: absolute;
            top: 8px;
            right: 8px;
        }
    }
    &-item-level-0 {
        border-top: 1px solid #D7E3EF;
        &-active {
            @extend .side-nav-item-level-0;
            color: $color-orange;
            > li {
                border: none;
            }
            .side-nav-list-level-1 {
                display: block;
            }
        }
    }
    &-link-level-0 {
        display: block;
        position: relative; // Used for negative margin for lines
        padding: 8px 20px 8px 0px;
        line-height: 16px;
        color: #4E5256;
        position: relative;
        &:visited {
            color: #4E5256;
        }
        &:hover {
            text-decoration: none;
            color: $color-orange;
        }
        &-active {
            @extend .side-nav-link-level-0;
            color: $color-orange;
            font-weight: bold;
            text-decoration: none;
            &:visited {
                color: $color-orange;
            }
            &:hover {
                text-decoration: none;
                color: $color-orange;
            }
            .side-nav-arrow {
                background: sassvg('ic-plus') no-repeat center right;
            }
            .side-nav-arrow-active {
                background: sassvg('ic-minus') no-repeat center right;
            }
            &:before {
                content: "";
                position: absolute;
                left: -15px;
                top: 50%;
                transform: translateY(-50%);
                width: 5px;
                height: 70%;
                background: $color-orange;
            }
        }
    }
    &-item-level-1 {
        border-top: 1px solid #D7E3EF;
        &-active {
            @extend .side-nav-item-level-1;
            font-weight: bold;
            > li {
                border: none;
            }
        }
    }
    &-link-level-1 {
        display: block;
        position: relative; // Used for negative margin for lines
        padding: 5px 20px;
        color: #4E5256;
        font-size: 12px;
        &:visited {
            color: #4E5256;
        }
        &:hover {
            text-decoration: none;
            color: $color-orange;
        }
        &-active {
            @extend .side-nav-link-level-1;
            color: $color-orange;
            text-decoration: none;
            font-weight: bold;
            &:visited {
                color: $color-orange;
            }
            &:hover {
                text-decoration: none;
                color: $color-orange;
            }
        }
    }
    &-list-level-1 {
        //display: none;
    }
    &-arrow {
        position: absolute;
        right: 7px;
        top: 2px;
        height: 30px;
        width: 30px;
        // Caution! Override in active link-level-0
        background: sassvg('ic-plus') no-repeat center right;
        &-active {
            background: sassvg('ic-minus') no-repeat center right;
        }
    }
}